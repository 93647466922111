:root {
  font-size: 10px;
  background: #30A9DE;
}

@font-face {
  font-family: 'Recursive';
  src: url('./recursive-MONO_CASL_wght_slnt_ital--full_gsub--2019_12_21-21_07.woff2') format('woff2-variations');
  font-weight: 300 1000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.glow {
  text-shadow: #EFDC05 1px 1px 3px;
  /* -webkit-text-shadow: 0px 0px 10px 1px rgba(239,220,5,1);
  -moz-text-shadow: 0px 0px 10px 1px rgba(239,220,5,1);
  text-shadow: 0px 0px 10px 1px rgba(239,220,5,1); */
}
